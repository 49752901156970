export default {
    namespaced: true,

    state: () => {
        return {
            loading: false,
            subjects: [],
        }
    },

    getters: {
        loading(state) {
            return state.loading;
        },

        subjects(state) {
            return state.subjects.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            });
        }
    },

    mutations: {
        setLoading(state, loading) {
            state.loading = loading
        },

        setLibrarySubject(state, subjects) {
            state.subjects = subjects
        },
    },

    actions: {
        async getLibrarySubjects(context) {
            context.commit('setLoading', true);
        
            await axios.get(`${this.state.host}/get-library-subjects`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('setLibrarySubject', res.data.subjects);
            })
            .catch(error => this.dispatch('snackbar/error', error))

            context.commit('setLoading', false);
        },

        async saveLibrarySubject(context, payload) {
            let isSuccess = false;

            let url = '';
            if(payload.id != null) {
                url = 'update-library-subject';
            } else {
                url = 'add-library-subject';
                delete payload.id
            }

            await axios.post(`${this.state.host}/${url}`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getLibrarySubjects');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async deleteLibrarySubject(context, id) {
            let isSuccess = false;

            await axios.delete(`${this.state.host}/delete-library-subject/${id}`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getLibrarySubjects');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },
    }
}
