export default {
    namespaced: true,

    state: () => {
        return {
            loading: false,
            vehicleLoading: false,
            drivers: [],
            vehicles: [],
        }
    },

    getters: {
        loading(state) {
            return state.loading;
        },

        vehicleLoading(state) {
            return state.vehicleLoading;
        },

        drivers(state) {
            return state.drivers.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            })
        },

        vehicles(state) {
            return state.vehicles.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            })
        }
    },

    mutations: {
        setLoading(state, loading) {
            state.loading = loading
        },

        setVehicleLoading(state, vehicleLoading) {
            state.vehicleLoading = vehicleLoading
        },

        setDriver(state, drivers) {
            state.drivers = drivers
        },

        setVehicle(state, vehicles) {
            state.vehicles = vehicles;
        }
    },

    actions: {
        async getDrivers(context, payload) {
            context.commit('setLoading', true);
            await axios.post(`${this.state.host}/get-drivers`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('setDriver', res.data.drivers);
            })
            .catch(error => this.dispatch('snackbar/error', error))

            context.commit('setLoading', false);
        },

        async saveDriver(context, payload) {
            let isSuccess = false;

            let url = '';
            if(payload.id != null) {
                url = 'update-driver';
            } else {
                url = 'add-driver';
                delete payload.id
            }

            await axios.post(`${this.state.host}/${url}`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getDrivers');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async deleteDriver(context, id) {
            let isSuccess = false;

            await axios.delete(`${this.state.host}/delete-driver/${id}`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getDrivers');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async getVehicles(context, payload) {
            context.commit('setVehicleLoading', true);
            await axios.post(`${this.state.host}/get-vehicles`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('setVehicle', res.data.vehicles);
            })
            .catch(error => this.dispatch('snackbar/error', error))

            context.commit('setVehicleLoading', false);
        },

        async saveVehicle(context, payload) {
            let isSuccess = false;

            let url = '';
            if(payload.id != null) {
                url = 'update-vehicle';
            } else {
                url = 'add-vehicle';
                delete payload.id
            }

            await axios.post(`${this.state.host}/${url}`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getVehicles');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async deleteVehicle(context, id) {
            let isSuccess = false;

            await axios.delete(`${this.state.host}/delete-vehicle/${id}`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getVehicles');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        }
    }
}