export default {
    namespaced: true,

    state: () => {
        return {
            loading: false,
            members: [],
            libraryMonitor: [],
            memberId: ''
        }
    },

    getters: {
        loading(state) {
            return state.loading;
        },

        members(state) {
            return state.members.map((item, sl) => {
                item.sl = sl + 1;
                item.display_name = `${item.member_id} - ${item.type == 'Student' ? item.student.name : item.employee.name}`;
                return item;
            });
        },

        libraryMonitor(state) {
            return state.libraryMonitor;
        },

        memberId(state) {
            return state.memberId;
        }
    },

    mutations: {
        setLoading(state, loading) {
            state.loading = loading
        },

        setMember(state, members) {
            state.members = members
        },

        setLibraryMonitor(state, libraryMonitor) {
            state.libraryMonitor = libraryMonitor
        },

        GenerateNewMemberId(state, id) {
            state.memberId = id;
        }
    },

    actions: {
        async getGeneratedMemberId(context) {
        
            await axios.get(`${this.state.host}/generate-member-id`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('GenerateNewMemberId', res.data);
            })
            .catch(error => this.dispatch('snackbar/error', error))

        },

        async getMembers(context, payload) {
            context.commit('setLoading', true);

            await axios.post(`${this.state.host}/get-members`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('setMember', res.data.members);
            })
            .catch(error => this.dispatch('snackbar/error', error))

            context.commit('setLoading', false);
        },

        async saveMember(context, payload) {
            let isSuccess = false;

            let url = '';

            if(payload.id != null) {
                url = 'update-member';
            } else {
                url = 'add-member';
                delete payload.id;
            }

            await axios.post(`${this.state.host}/${url}`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getMembers');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async deleteMember(context, id) {
            let isSuccess = false;

            await axios.delete(`${this.state.host}/delete-member/${id}`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getMembers');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async libraryMonitor(context) 
        {
            context.commit('setLoading', true);
        
            await axios.get(`${this.state.host}/library-monitor`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('setLibraryMonitor', res.data.libraryMonitor);
            })
            .catch(error => this.dispatch('snackbar/error', error))

            context.commit('setLoading', false);
        }
    }
}