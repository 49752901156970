export default {
    namespaced: true,

    state: () => {
        return {
            loading: false,
            hostels: [],
            hostelMonitor: [],
            hostelTransactions: [],
            tnxCode: '',
        }
    },

    getters: {
        loading(state) {
            return state.loading;
        },

        hostels(state) {
            return state.hostels.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            })
        },

        hostelMonitor(state) {
            return state.hostelMonitor;
        },

        hostelTransactions(state) {
            return state.hostelTransactions.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            })
        },

        generateCode(state) {
            return state.tnxCode;
        }
    },

    mutations: {
        setLoading(state, loading) {
            state.loading = loading;
        },

        setHostel(state, hostels) {
            state.hostels = hostels;
        },

        setHostelMonitor(state, hostelMonitor) {
            state.hostelMonitor = hostelMonitor;
        },

        setHostelTnxCode(state, code) {
            state.tnxCode = code
        },

        setHostelTransaction(state, hostelTransactions) {
            state.hostelTransactions = hostelTransactions
        }

    },

    actions: {
        async getHostels(context) {
            context.commit('setLoading', true);

            await axios.get(`${this.state.host}/get-hostels`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('setHostel', res.data.hostels);
            })
            .catch(error => this.dispatch('snackbar/error', error))

            context.commit('setLoading', false);
        },

        async saveHostel(context, payload) {
            let isSuccess = false;

            let url = '';
            if(payload.id != null) {
                url = 'update-hostel';
            } else {
                url = 'add-hostel';
                delete payload.id
            }

            await axios.post(`${this.state.host}/${url}`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getHostels');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async deleteHostel(context, id) {
            let isSuccess = false;

            await axios.delete(`${this.state.host}/delete-hostel/${id}`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getHostels');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async hostelMonitor(context) 
        {
            context.commit('setLoading', true);
        
            await axios.get(`${this.state.host}/hostel-monitor`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('setHostelMonitor', res.data.hostelMonitor);
            })
            .catch(error => this.dispatch('snackbar/error', error))

            context.commit('setLoading', false);
        },

        async getHostelCashTransactionCode(context) {
            await axios.get(`${this.state.host}/get-hostel-cash-transaction-code`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('setHostelTnxCode', res.data.code);
            })
            .catch(error => this.dispatch('snackbar/error', error.response.data.message))
        },

        async getHostelCashTransactions(context, payload) {
            await axios.post(`${this.state.host}/get-hostel-cash-transactions`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('setHostelTransaction', res.data.transactions);
            })
            .catch(error => this.dispatch('snackbar/error', error.response.data.message))
        },

        async saveHostelCashTransaction(context, payload) {
            let isSuccess = false;

            let url = '';
            if(payload.id != null) {
                url = 'update-hostel-cash-transaction';
            } else {
                url = 'add-hostel-cash-transaction';
                delete payload.id
            }

            await axios.post(`${this.state.host}/${url}`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async deleteHostelCashTransaction(context, id) {
            let isSuccess = false;

            await axios.delete(`${this.state.host}/delete-hostel-cash-transaction/${id}`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        }
    }
}