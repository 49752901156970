export default {
    namespaced: true,

    state: () => {
        return {
            loading: false,
            exams: [],
            routines: [],
        }
    },

    getters: {
        loading(state) {
            return state.loading;
        },

        exams(state) {
            return state.exams.map((item, sl) => {
                item.sl = sl + 1;
                item.status = item.status == 'a' ? 'Active' : 'Inactive';
                return item;
            });
        },

        routines(state) {
            return state.routines.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            });
        }
    },

    mutations: {
        setLoading(state, loading) {
            state.loading = loading
        },

        setExam(state, exams) {
            state.exams = exams
        },

        setRoutine(state, routine) {
            state.routines = routine
        }
    },

    actions: {
        async getExams(context, payload) {
            context.commit('setLoading', true);
        
            await axios.post(`${this.state.host}/get-exams`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('setExam', res.data.exams);
            })
            .catch(error => this.dispatch('snackbar/error', error))

            context.commit('setLoading', false);
        },

        async saveExam(context, payload) {
            let isSuccess = false;

            let url = '';
            if(payload.id != null) {
                url = 'update-exam';
            } else {
                url = 'add-exam';
                delete payload.id
            }

            await axios.post(`${this.state.host}/${url}`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getExams');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async deleteExam(context, id) {
            let isSuccess = false;

            await axios.delete(`${this.state.host}/delete-exam/${id}`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getExams');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async updateStatus(context, payload) {
            let isSuccess = false;

            await axios.post(`${this.state.host}/update-exam-status`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getExams');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async getRoutines(context, payload) {
            context.commit('setLoading', true);
        
            await axios.post(`${this.state.host}/get-exam-routines`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('setRoutine', res.data.routines);
            })
            .catch(error => this.dispatch('snackbar/error', error))

            context.commit('setLoading', false);
        },

        async saveExamRoutine(context, payload) {
            let isSuccess = false;

            let url = '';
            if(payload.id != null) {
                url = 'update-exam-routine';
            } else {
                url = 'add-exam-routine';
                delete payload.id
            }

            await axios.post(`${this.state.host}/${url}`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getRoutines');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async deleteExamRoutine(context, id) {
            let isSuccess = false;

            await axios.delete(`${this.state.host}/delete-exam-routine/${id}`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getRoutines');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        }
    }
}
