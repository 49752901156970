export default {
    namespaced: true,

    state: () => {
        return {
            loading: false,
            bankTypes: [],
        }
    },

    getters: {
        loading(state) {
            return state.loading;
        },

        bankTypes(state) {
            return state.bankTypes.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            });
        }
    },

    mutations: {
        setLoading(state, loading) {
            state.loading = loading
        },

        setBankType(state, bankTypes) {
            state.bankTypes = bankTypes
        },
    },

    actions: {
        async getBankTypes(context) {
            context.commit('setLoading', true);
        
            await axios.get(`${this.state.host}/get-bank-types`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('setBankType', res.data.bankTypes);
            })
            .catch(error => this.dispatch('snackbar/error', error))

            context.commit('setLoading', false);
        },

        async saveBankType(context, payload) {
            let isSuccess = false;

            let url = '';
            if(payload.id != null) {
                url = 'update-bank-type';
            } else {
                url = 'add-bank-type';
                delete payload.id
            }

            await axios.post(`${this.state.host}/${url}`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getBankTypes');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async deleteBankType(context, id) {
            let isSuccess = false;

            await axios.delete(`${this.state.host}/delete-bank-type/${id}`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getBankTypes');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        }
    }
}
