<template>
    <v-container fluid>
        <v-row dense class="pt-2">
            <v-col cols="12">
                <v-row justify="center">
                    <v-col cols="12" md="10">
                        <v-row justify="center" class=" py-3">
                            <v-img
                                max-width="500"
                                src="@/assets/logo.jpeg"
                            ></v-img>
                        </v-row>

                        <v-divider class="mt-2"></v-divider>

                        <v-row class="pt-2">
                            <v-col cols="12" md="3">
                                <v-card to="/student" elevation="1" color="blue">
                                    <v-card-text class="module-card-text">
                                        <v-icon size="55">mdi-human-child</v-icon>
                                    </v-card-text>
                                    <div class="module-card-title">Student Module</div>
                                </v-card>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-card to="/employee" elevation="1" color="lime">
                                    <v-card-text class="module-card-text">
                                        <v-icon size="55">mdi-account-group</v-icon>
                                    </v-card-text>
                                    <div class="module-card-title">Employee Module</div>
                                </v-card>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-card to="/exam" elevation="1" color="orange">
                                    <v-card-text class="module-card-text">
                                        <v-icon size="55">mdi-hammer-screwdriver</v-icon>
                                    </v-card-text>
                                    <div class="module-card-title">Exam & Result Module</div>
                                </v-card>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-card to="/fees-collection" elevation="1" color="pink">
                                    <v-card-text class="module-card-text">
                                        <v-icon size="55">mdi-cash-register</v-icon>
                                    </v-card-text>
                                    <div class="module-card-title">Account Module</div>
                                </v-card>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-card to="/hostel-monitor" elevation="1" color="cyan">
                                    <v-card-text class="module-card-text">
                                        <v-icon size="55">mdi-account-group</v-icon>
                                    </v-card-text>
                                    <div class="module-card-title">Hostel Module</div>
                                </v-card>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-card to="/library-monitor" elevation="1" color="teal">
                                    <v-card-text class="module-card-text">
                                        <v-icon size="55">mdi-library</v-icon>
                                    </v-card-text>
                                    <div class="module-card-title">Library Module</div>
                                </v-card>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-card to="/inventory-monitor" elevation="1" color="yellow">
                                    <v-card-text class="module-card-text">
                                        <v-icon size="55">mdi-cart</v-icon>
                                    </v-card-text>
                                    <div class="module-card-title">Inventory Module</div>
                                </v-card>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-card to="/asset-entry" elevation="1" color="green">
                                    <v-card-text class="module-card-text">
                                        <v-icon size="55">mdi-currency-bdt</v-icon>
                                    </v-card-text>
                                    <div class="module-card-title">Asset & Property Module</div>
                                </v-card>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-card to="/vehicle-entry" elevation="1" color="amber">
                                    <v-card-text class="module-card-text">
                                        <v-icon size="55">mdi-car</v-icon>
                                    </v-card-text>
                                    <div class="module-card-title">Transport Module</div>
                                </v-card>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-card to="/basic-setting" elevation="1" color="light-green">
                                    <v-card-text class="module-card-text">
                                        <v-icon size="55">mdi-cog-outline</v-icon>
                                    </v-card-text>
                                    <div class="module-card-title">Administration</div>
                                </v-card>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-card to="/business-monitor" elevation="1" color="blue-grey lighten-4">
                                    <v-card-text class="module-card-text">
                                        <v-icon size="55">mdi-chart-bar</v-icon>
                                    </v-card-text>
                                    <div class="module-card-title">Business Monitor</div>
                                </v-card>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-card elevation="1" color="light-blue lighten-4" @click="getAttendanceProcess">
                                    <v-card-text class="module-card-text">
                                        <v-icon size="55">mdi-fingerprint</v-icon>
                                    </v-card-text>
                                    <div class="module-card-title">Attendance Process</div>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'Dashboard',

    methods: {
        async getAttendanceProcess() {
            await this.$store.dispatch('student/attendanceProcess')
        }
    }
}
</script>

<style lang="scss" scoped>
.module-card-text {
    padding: 12px 5px 3px;
    text-align: center;

    .v-icon {
        color: #263238 !important;
    }
}
.module-card-title {
    text-align: center;
    padding-bottom: 10px;
    font-weight: bold;
}
</style>