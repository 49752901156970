export default {
    namespaced: true,

    state: () => {
        return {
            loading: false,
            issues: [],
            issueId: ''
        }
    },

    getters: {
        loading(state) {
            return state.loading;
        },

        issues(state) {
            return state.issues.filter(item => item.issue_details.length > 0).map((item, sl) => {
                item.sl = sl + 1;
                return item;
            })
        },

        issueId(state) {
            return state.issueId;
        }
    },

    mutations: {
        setLoading(state, loading) {
            state.loading = loading;
        },

        setIssue(state, issues) {
            state.issues = issues;
        },

        GenerateNewIssueId(state, id) {
            state.issueId = id;
        }
    },

    actions: {
        async getGeneratedIssueId(context) {
        
            await axios.get(`${this.state.host}/generate-issue-id`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('GenerateNewIssueId', res.data);
            })
            .catch(error => this.dispatch('snackbar/error', error))

        },

        async getIssues(context, payload) {
            context.commit('setLoading', true);

            await axios.post(`${this.state.host}/get-issues`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('setIssue', res.data.issues);
            })
            .catch(error => this.dispatch('snackbar/error', error))

            context.commit('setLoading', false);
        },

        async saveIssue(context, {issue, details}) {
            let isSuccess = false;

            let url = '';

            if(issue.id != 0) {
                url = 'update-issue';
            } else {
                url = 'add-issue';
                delete issue.id;
            }

            let fd = new FormData();
            fd.append('issue', JSON.stringify(issue));
            fd.append('details', JSON.stringify(details));

            await axios.post(`${this.state.host}/${url}`, fd, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getIssues');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async deleteIssue(context, id) {
            let isSuccess = false;

            await axios.delete(`${this.state.host}/delete-issue/${id}`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getIssues');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async bookReceive(context, payload) {
            let isSuccess = false;

            await axios.post(`${this.state.host}/book-receive`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getIssues');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        }
    }
}