export default {
    namespaced: true,

    state: () => {
        return {
            loading: false,
            sections: [],
        }
    },

    getters: {
        loadingSection(state) {
            return state.loading;
        },

        sections(state) {
            return state.sections.map((item, sl) => {
                item.sl = sl + 1;
                item.status = item.status == 'a' ? 'Active' : 'Inactive';
                return item;
            });
        }
    },

    mutations: {
        setLoading(state, loading) {
            state.loading = loading
        },

        setSection(state, sections) {
            state.sections = sections
        },
    },

    actions: {
        async getSections(context, payload) {
            context.commit('setLoading', true);
        
            await axios.post(`${this.state.host}/get-sections`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('setSection', res.data.sections);
            })
            .catch(error => this.dispatch('snackbar/error', error))

            context.commit('setLoading', false);
        },

        async saveSection(context, payload) {
            let isSuccess = false;

            let url = '';
            if(payload.id != null) {
                url = 'update-section';
            } else {
                url = 'add-section';
                delete payload.id
            }

            await axios.post(`${this.state.host}/${url}`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getSections');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async deleteSection(context, id) {
            let isSuccess = false;

            await axios.delete(`${this.state.host}/delete-section/${id}`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getSections');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async updateStatus(context, payload) {
            let isSuccess = false;

            await axios.post(`${this.state.host}/update-section-status`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getSections');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        }
    }
}
