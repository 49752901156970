export default {
    namespaced: true,

    state: () => {
        return {
            loading: false,
            products: [],
            stock: []
        }
    },

    getters: {
        loading(state) {
            return state.loading;
        },

        products(state) {
            return state.products.map((item, sl) => {
                item.sl = sl + 1;
                item.display_name = `${item.code} - ${item.name}`
                return item;
            });
        },

        stock(state) {
            return state.stock.map((item) => {
                item.sale_quantity = (+item.sale_quantity + +item.service_quantity);
                return item;
            });
        }
    },

    mutations: {
        setLoading(state, loading) {
            state.loading = loading
        },

        setProduct(state, products) {
            state.products = products
        },

        setStock(state, stock) {
            state.stock = stock
        }
    },

    actions: {
        async getProducts(context, payload) {
            context.commit('setLoading', true);
            await axios.post(`${this.state.host}/get-products`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('setProduct', res.data.products);
            })
            .catch(error => this.dispatch('snackbar/error', error))

            context.commit('setLoading', false);
        },

        async generateProductCode(context) {
            let newCode = await axios.get(`${this.state.host}/generate-product-code`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                return res.data.code;
            })
            .catch(error => this.dispatch('snackbar/error', error))
            
            return newCode;
        },

        async saveProduct(context, payload) {
            let isSuccess = false;

            let url = '';
            if(payload.id != null) {
                url = 'update-product';
            } else {
                url = 'add-product';
                delete payload.id
            }

            await axios.post(`${this.state.host}/${url}`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getProducts');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async deleteProduct(context, id) {
            let isSuccess = false;

            await axios.delete(`${this.state.host}/delete-product/${id}`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getProducts');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async getProductStock(context, payload) {
            context.commit('setLoading', true);
            await axios.post(`${this.state.host}/get-product-stock`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('setStock', res.data.stock);
            })
            .catch(error => this.dispatch('snackbar/error', error))

            context.commit('setLoading', false);
        },

        async getProductLedger(context, payload) {
            let ledger = await axios.post(`${this.state.host}/get-product-ledger`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                return res.data.ledger;
            })
            .catch(error => this.dispatch('snackbar/error', error))
            
            return ledger;   
        },

        async getTopSaleProduct(context) {
            let product = await axios.get(`${this.state.host}/get-top-sale-product`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                return res.data.product;
            })
            .catch(error => this.dispatch('snackbar/error', error))
            
            return product;   
        }
    }
}
