export default {
    namespaced: true,

    state: () => {
        return {
            loading: false,
            holidays: [],
        }
    },

    getters: {
        loading(state) {
            return state.loading;
        },

        holidays(state) {
            return state.holidays;
        }
    },

    mutations: {
        setLoading(state, loading) {
            state.loading = loading
        },

        setHoliday(state, holidays) {
            state.holidays = holidays
        },
    },

    actions: {
        async getHolidays(context) {
            context.commit('setLoading', true);
        
            await axios.get(`${this.state.host}/get-holidays`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('setHoliday', res.data.holidays);
            })
            .catch(error => this.dispatch('snackbar/error', error))

            context.commit('setLoading', false);
        },

        async saveHoliday(context, payload) {
            let isSuccess = false;

            let url = '';
            if(payload.id != null) {
                url = 'update-holiday';
            } else {
                url = 'add-holiday';
                delete payload.id
            }

            await axios.post(`${this.state.host}/${url}`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getholidays');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async deleteHoliday(context, id) {
            let isSuccess = false;

            await axios.delete(`${this.state.host}/delete-holiday/${id}`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getholidays');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async saveWeekend(context, payload) {
            let isSuccess = false;

            await axios.post(`${this.state.host}/save-weekend`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        }
    }
}