export default {
    namespaced: true,

    state: () => ({
        profile: null,
    }),

    getters: {
        profile(state) {
            return state.profile
        },
    },

    mutations: {
        changeContent(state, payload) {
            state.profile = payload
        },
    },

    actions: {
        async getCompanyProfile(context) {
            await axios.get(`${this.state.host}/get-contents`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('changeContent', res.data.profile);
            })
            .catch(error => this.dispatch('snackbar/error', error.message))
        },

        async saveCompanyProfile(context, { content, image }) {
            let isSuccess = false
            let url = 'update-content';
        
            let fd = new FormData();
            Object.keys(content).map(k => {
                if (content[k] != null) fd.append(k, content[k])
            })

            if(image != null && image != undefined) {
                fd.append('image', image);
            }

            await axios.post(`${this.state.host}/${url}`, fd, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            }).then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getCompanyProfile');
            }).catch(err => {
                isSuccess = false;
                this.dispatch('snackbar/error', err.response.data.message);
            })

            return isSuccess;
        },
    }
}