export default {
    namespaced: true,

    state: () => {
        return {
            loading: false,
            classes: [],
        }
    },

    getters: {
        loadingClass(state) {
            return state.loading;
        },

        classes(state) {
            return state.classes.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            });
        }
    },

    mutations: {
        setLoading(state, loading) {
            state.loading = loading
        },

        setClass(state, classes) {
            state.classes = classes
        },
    },

    actions: {
        async getClasses(context) {
            context.commit('setLoading', true);
        
            await axios.get(`${this.state.host}/get-classes`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('setClass', res.data.classes);
            })
            .catch(error => this.dispatch('snackbar/error', error))

            context.commit('setLoading', false);
        },

        async saveClass(context, payload) {
            let isSuccess = false;

            let url = '';
            if(payload.id != null) {
                url = 'update-class';
            } else {
                url = 'add-class';
                delete payload.id
            }

            await axios.post(`${this.state.host}/${url}`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getClasses');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async deleteClass(context, id) {
            let isSuccess = false;

            await axios.delete(`${this.state.host}/delete-class/${id}`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getClasses');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        }
    }
}