<template>
    <v-app>
        <div class="area">
            <ul class="circles">
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
            </ul>
        </div>

        <v-container fluid style="z-index:1">
            <v-row no-gutters justify="center" class="mt-15">
                <v-col cols="12">
                    <h1 class="text-center text-capitalize heading" style="color: #fff">
                        <span class="typed-text">{{ typeValue }}</span>
                        <span class="blinking-cursor">|</span>
                        <span class="cursor" :class="{ typing: typeStatus }">&nbsp;</span>
                    </h1>
                </v-col>
                <v-col cols="8">
                    <v-row no-gutters style="border:1px solid #ccc">
                        <v-col cols="7" sm="7" md="7" lg="7"  class="hidden-xs-only">
                            <v-img
                                lazy-src="@/assets/login.jpeg"
                                src="@/assets/login.jpeg"
                                height="370px"
                                width="100%"
                                cover
                            ></v-img>
                        </v-col>
                        <v-col cols="12" sm="5" md="5" lg="5">
                            <v-card :elevation="4" dark color="blue darken-4" height="370px" class="text-center px-8 py-10">
                                <v-icon x-large class="mt-5">mdi-account-circle</v-icon>
                                <h3 class="mt-2">LOGIN</h3>
                                <v-form ref="loginForm" @submit.prevent="login">
                                    <v-text-field 
                                        outlined 
                                        dense 
                                        hide-details
                                        light
                                        autofocus
                                        prepend-inner-icon="mdi-account"
                                        class="mt-4"
                                        prefix=" | "
                                        background-color="white"
                                        v-model="user.username"
                                        :rules="[() => !!user.username || '']"
                                    >
                                    </v-text-field>
                                    <v-text-field 
                                        outlined 
                                        dense 
                                        hide-details
                                        light
                                        prepend-inner-icon="mdi-key"
                                        class="mt-2"
                                        prefix=" | "
                                        background-color="white"
                                        type="password"
                                        v-model="user.password"
                                        :rules="[() => !!user.password || '', validatePassword.length]"
                                    >
                                    </v-text-field>
                                    <v-btn block type="submit" :loading="loading" color="success" class="mt-4">Login</v-btn>
                                </v-form>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </v-app>
</template>

<script>
import utilities from './../mixins/utilities'

export default {
    name: 'Login',

    mixins: [utilities],
    
    data: () => ({
        user: {
            username: '',
            password: '',
        },
        orgName: '',
        email: '',
        phone: '',
        image: '',
        loading: false,

        typeValue: "",
        typeStatus: false,
        displayTextArray: ["Education Management Software"],
        typingSpeed: 200,
        erasingSpeed: 100,
        newTextDelay: 2000,
        displayTextArrayIndex: 0,
        charIndex: 0,
    }),

    async created() {
        let jwtToken = localStorage.getItem('jwtToken')
        if (jwtToken != null && jwtToken != '') this.$router.push('/')

        setTimeout(this.typeText, this.newTextDelay + 200);
    },

    methods: {
        async login() {
            let isValid = true;

            this.$refs.loginForm.inputs.forEach(input => {
                if(input.hasError) {
                    isValid = false;
                    return;
                }
            })

            if(!isValid) {
                return;
            }

            this.loading = true;
            let isSuccess = await this.$store.dispatch('user/login', this.user);
            if(isSuccess) {
                this.$router.push('/');
            }
            this.loading = false;
        },

        typeText() {
            if (this.charIndex < this.displayTextArray[this.displayTextArrayIndex].length) {
                if (!this.typeStatus) this.typeStatus = true;
                this.typeValue += this.displayTextArray[this.displayTextArrayIndex].charAt(
                    this.charIndex
                );
                this.charIndex += 1;
                setTimeout(this.typeText, this.typingSpeed);
            } else {
                this.typeStatus = false;
                setTimeout(this.eraseText, this.newTextDelay);
            }
        },

        eraseText() {
            if (this.charIndex > 0) {
                if (!this.typeStatus) this.typeStatus = true;
                this.typeValue = this.displayTextArray[this.displayTextArrayIndex].substring(0, this.charIndex - 1);
                this.charIndex -= 1;
                setTimeout(this.eraseText, this.erasingSpeed);
            } else {
                this.typeStatus = false;
                this.displayTextArrayIndex += 1;
                if (this.displayTextArrayIndex >= this.displayTextArray.length)
                this.displayTextArrayIndex = 0;
                setTimeout(this.typeText, this.typingSpeed + 1000);
            }
        },
    }
}
</script>

<style lang="scss" scoped>
    * {
        margin: 0px;
        padding: 0px;
    }

    .heading {
        font-size: 3rem;
        font-weight: normal;
        span.typed-text {
            color: #fff;
        }
    }

    .area {
        background: #4e54c8;
        background: -webkit-linear-gradient(to left, #8f94fb, #4e54c8);
        width: 100%;
        height: 100vh;
        position: absolute;
        top: 0;
        z-index: 0;
    }

    .circles {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    .circles li {
        position: absolute;
        display: block;
        list-style: none;
        width: 20px;
        height: 20px;
        background: rgba(255, 255, 255, 0.2);
        animation: animate 25s linear infinite;
        bottom: -150px;
    }

    .circles li:nth-child(1) {
        left: 25%;
        width: 80px;
        height: 80px;
        animation-delay: 0s;
    }

    .circles li:nth-child(2) {
        left: 10%;
        width: 20px;
        height: 20px;
        animation-delay: 2s;
        animation-duration: 12s;
    }

    .circles li:nth-child(3) {
        left: 70%;
        width: 20px;
        height: 20px;
        animation-delay: 4s;
    }

    .circles li:nth-child(4) {
        left: 40%;
        width: 60px;
        height: 60px;
        animation-delay: 0s;
        animation-duration: 18s;
    }

    .circles li:nth-child(5) {
        left: 65%;
        width: 20px;
        height: 20px;
        animation-delay: 0s;
    }

    .circles li:nth-child(6) {
        left: 75%;
        width: 110px;
        height: 110px;
        animation-delay: 3s;
    }

    .circles li:nth-child(7) {
        left: 35%;
        width: 150px;
        height: 150px;
        animation-delay: 7s;
    }

    .circles li:nth-child(8) {
        left: 50%;
        width: 25px;
        height: 25px;
        animation-delay: 15s;
        animation-duration: 45s;
    }

    .circles li:nth-child(9) {
        left: 20%;
        width: 15px;
        height: 15px;
        animation-delay: 2s;
        animation-duration: 35s;
    }

    .circles li:nth-child(10) {
        left: 85%;
        width: 150px;
        height: 150px;
        animation-delay: 0s;
        animation-duration: 11s;
    }

    @keyframes animate {
        0% {
            transform: translateY(0) rotate(0deg);
            opacity: 1;
            border-radius: 0;
        }

        100% {
            transform: translateY(-1000px) rotate(720deg);
            opacity: 0;
            border-radius: 50%;
        }
    }
    @media screen and (min-device-width: 310px) and (max-device-width: 768px) { 
        .heading {
            font-size: 19px;
            font-weight: bold;
            span.typed-text {
                color: #fff;
            }
        }
    }
</style>