export default {
    namespaced: true,

    state: () => {
        return {
            loading: false,
            routines: [],
            weeks: []
        }
    },

    getters: {
        loading(state) {
            return state.loading;
        },

        routines(state) {
            return state.routines.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            });
        },

        weeks(state) {
            return state.weeks;
        }
    },

    mutations: {
        setLoading(state, loading) {
            state.loading = loading
        },

        setRoutine(state, routines) {
            state.routines = routines
        },

        setWeek(state, weeks) {
            state.weeks = weeks;
        }
    },

    actions: {
        async getWeeks(context) {
            await axios.get(`${this.state.host}/get-weeks`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('setWeek', res.data.weeks);
            })
            .catch(error => this.dispatch('snackbar/error', error))

        },

        async getRoutines(context, payload) {
            context.commit('setLoading', true);
        
            await axios.post(`${this.state.host}/get-routines`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('setRoutine', res.data.routines);
            })
            .catch(error => this.dispatch('snackbar/error', error))

            context.commit('setLoading', false);
        },

        async saveRoutine(context, payload) {
            let isSuccess = false;

            let url = '';
            if(payload.id != null) {
                url = 'update-routine';
            } else {
                url = 'add-routine';
                delete payload.id
            }

            await axios.post(`${this.state.host}/${url}`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                // context.dispatch('getRoutines');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async deleteRoutine(context, id) {
            let isSuccess = false;

            await axios.delete(`${this.state.host}/delete-routine/${id}`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                // context.dispatch('getRoutines');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async getClassRoutines(context, payload) {
            let routine = await axios.post(`${this.state.host}/get-class-routines`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                return res.data.routines;
            })
            .catch(error => this.dispatch('snackbar/error', error))

            return routine;
        }
    }
}
