export default {
    namespaced: true,

    state: () => {
        return {
            loading: false,
            purchases: [],
            purchaseReturns: []
        }
    },

    getters: {
        loading(state) {
            return state.loading;
        },

        purchases(state) {
            return state.purchases.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            });
        },

        purchaseReturns(state) {
            return state.purchaseReturns.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            })
        }
    },

    mutations: {
        setLoading(state, loading) {
            state.loading = loading
        },

        setPurchase(state, purchases) {
            state.purchases = purchases
        },

        setPurchaseReturn(state, returns) {
            state.purchaseReturns = returns;
        }
    },

    actions: {
        async getPurchases(context, payload) {
            context.commit('setLoading', true);
            await axios.post(`${this.state.host}/get-purchases`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('setPurchase', res.data.purchases);
            })
            .catch(error => this.dispatch('snackbar/error', error))

            context.commit('setLoading', false);
        },

        async generatePurchaseInvoice(context) {
            let newCode = await axios.get(`${this.state.host}/generate-purchase-invoice`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                return res.data.invoice;
            })
            .catch(error => this.dispatch('snackbar/error', error))
            
            return newCode;
        },

        async savePurchase(context, payload) {
            let response = {success: false};

            let url = '';
            if(payload.purchase.id != null) {
                url = 'update-purchase';
            } else {
                url = 'add-purchase';
                delete payload.purchase.id
            }

            await axios.post(`${this.state.host}/${url}`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                response.success = true;
                response.purchaseId = res.data.purchaseId;
                this.dispatch('snackbar/success', res.data.message);
                // context.dispatch('getPurchases');
            })
            .catch(error => { 
                response.success = false;
                this.dispatch('snackbar/error', error);
            })

            return response;
        },

        async deletePurchase(context, id) {
            let isSuccess = false;

            await axios.delete(`${this.state.host}/delete-purchase/${id}`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                // context.dispatch('getPurchases');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async getPurchaseReteurnForDetails(context, payload) {
            let purchase = await axios.post(`${this.state.host}/get-purchase-for-return`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                return res.data.purchases;
            })
            .catch(error => {
                this.dispatch('snackbar/error', error)
            })
            return purchase;
            // context.commit('setForReturn', purchase);
        },

        async getPurchaseReturns(context, payload) {
            context.commit('setLoading', true);
            await axios.post(`${this.state.host}/get-purchase-returns`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('setPurchaseReturn', res.data.returns);
            })
            .catch(error => this.dispatch('snackbar/error', error))

            context.commit('setLoading', false);
        },

        async savePurchaseReturn(context, payload) {
            let response = {success: false};
            delete payload.purchaseReturn.supplier_name
            
            let url = '';
            if(payload.purchaseReturn.id != null) {
                url = 'update-purchase-return';
            } else {
                url = 'add-purchase-return';
                delete payload.purchaseReturn.id
            }

            await axios.post(`${this.state.host}/${url}`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                response.success = true;
                response.returnId = res.data.returnId;
                this.dispatch('snackbar/success', res.data.message);
                // context.dispatch('getPurchases');
            })
            .catch(error => { 
                response.success = false;
                this.dispatch('snackbar/error', error);
            })

            return response;
        },

        async purchaseReturnDelete(context, id) {
            let isSuccess = false;

            await axios.delete(`${this.state.host}/delete-purchase-return/${id}`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                // context.dispatch('getPurchases');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        }

    }
}
