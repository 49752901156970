export default {
    namespaced: true,

    state: () => {
        return {
            loading: false,
            collections: [],
            dues: [],
            payments: [],
            dailyCollection: [],
        }
    },

    getters: {
        loading(state) {
            return state.loading;
        },

        collections(state) {
            return state.collections.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            });
        },

        dues(state) {
            return state.dues;
        },

        payments(state) {
            return state.payments;
        },

        dailyCollection(state) {
            return state.dailyCollection;
        }
    },

    mutations: {
        setLoading(state, loading) {
            state.loading = loading
        },

        setCollection(state, collections) {
            state.collections = collections
        },

        setDues(state, dues) {
            state.dues = dues;
        },

        setPayments(state, payments) {
            state.payments = payments;
        },

        setStudentCollection(state, payment) {
            state.dailyCollection = payment
        }
    },

    actions: {
        async getCollections(context) {
            context.commit('setLoading', true);
        
            await axios.get(`${this.state.host}/get-collections`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('setCollection', res.data.collections);
            })
            .catch(error => this.dispatch('snackbar/error', error))

            context.commit('setLoading', false);
        },

        async getCollectionPayment(context, payload) {
            context.commit('setLoading', true);
        
            await axios.post(`${this.state.host}/get-collection-payments`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('setPayments', res.data.collections);
            })
            .catch(error => this.dispatch('snackbar/error', error))

            context.commit('setLoading', false);
        },

        async getCollectionDue(context, payload) {
            context.commit('setLoading', true);
        
            await axios.post(`${this.state.host}/get-collection-dues`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('setDues', res.data.dues);
            })
            .catch(error => this.dispatch('snackbar/error', error))

            context.commit('setLoading', false); 
        },

        async collectionSettings(context, payload) {
            let isSuccess = false;
            let url = 'add-collection-setting';

            await axios.post(`${this.state.host}/${url}`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                // context.dispatch('getCollections');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async paymentColleciton(context, payload) {
            let response = {success: false};

            await axios.post(`${this.state.host}/collection-payment`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                response.success = true;
                response.collectionId = res.data.collectionId;
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => { 
                response.success = false;
                this.dispatch('snackbar/error', error);
            })

            return response;
        },

        async deletePaymentCollection(context, id) {
            let isSuccess = false;

            await axios.delete(`${this.state.host}/delete-collection-payment/${id}`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async getStudentPayments(context, payload) {
            await axios.post(`${this.state.host}/get-student-payments`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('setStudentCollection', res.data.payments);
            })
            .catch(error => this.dispatch('snackbar/error', error))
        },

        async getFeesHeadwiseCollection(context, payload) {
            let payments = await axios.post(`${this.state.host}/get-fees-collections`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                return  res.data.payments;
            })
            .catch(error => this.dispatch('snackbar/error', error))

            return payments;
        },

        async getServiceCollection(context, payload) {
            let services = await axios.post(`${this.state.host}/get-service-collections`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                return  res.data.services;
            })
            .catch(error => this.dispatch('snackbar/error', error))

            return services;
        }
    }
}
