export default {
    namespaced: true,

    state: () => {
        return {
            loading: false,
            sales: [],
        }
    },

    getters: {
        loading(state) {
            return state.loading;
        },

        sales(state) {
            return state.sales.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            });
        },
    },

    mutations: {
        setLoading(state, loading) {
            state.loading = loading
        },

        setSale(state, sales) {
            state.sales = sales
        },
    },

    actions: {
        async getSales(context, payload) {
            context.commit('setLoading', true);
            await axios.post(`${this.state.host}/get-sales`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('setSale', res.data.sales);
            })
            .catch(error => this.dispatch('snackbar/error', error))

            context.commit('setLoading', false);
        },

        async generateSaleInvoice(context) {
            let newCode = await axios.get(`${this.state.host}/generate-sale-invoice`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                return res.data.invoice;
            })
            .catch(error => this.dispatch('snackbar/error', error))
            
            return newCode;
        },

        async saveSale(context, payload) {
            let response = {success: false};

            let url = '';
            if(payload.sale.id != null) {
                url = 'update-sale';
            } else {
                url = 'add-sale';
                delete payload.sale.id
            }

            await axios.post(`${this.state.host}/${url}`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                response.success = true;
                response.saleId = res.data.saleId;
                this.dispatch('snackbar/success', res.data.message);
                // context.dispatch('getSales');
            })
            .catch(error => { 
                response.success = false;
                this.dispatch('snackbar/error', error);
            })

            return response;
        },

        async deleteSale(context, id) {
            let isSuccess = false;

            await axios.delete(`${this.state.host}/delete-sale/${id}`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                // context.dispatch('getSales');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async getLast24Hourse(context) {
            let sales = await axios.get(`${this.state.host}/get-last-sales`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                return res.data.sales;
            })
            .catch(error => this.dispatch('snackbar/error', error))

            return sales;
        },

        async getSaleGraphData(context) {
            let sales = await axios.get(`${this.state.host}/get-sale-ghraps`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                return res.data.sales;
            })
            .catch(error => this.dispatch('snackbar/error', error))

            return sales;
        }
    }
}
