export default {
    namespaced: true,

    state: () => {
        return {
            loading: false,
            notices: [],
        }
    },

    getters: {
        loading(state) {
            return state.loading;
        },

        notices(state) {
            return state.notices.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            });
        }
    },

    mutations: {
        setLoading(state, loading) {
            state.loading = loading
        },

        setNotice(state, notices) {
            state.notices = notices
        },
    },

    actions: {
        async getNotices(context) {
            context.commit('setLoading', true);
        
            await axios.get(`${this.state.host}/get-notices`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('setNotice', res.data.notices);
            })
            .catch(error => this.dispatch('snackbar/error', error))

            context.commit('setLoading', false);
        },

        async saveNotice(context, payload) {
            let isSuccess = false;

            let url = '';
            if(payload.id != null) {
                url = 'update-notice';
            } else {
                url = 'add-notice';
                delete payload.id
            }

            await axios.post(`${this.state.host}/${url}`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getNotices');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async deleteNotice(context, id) {
            let isSuccess = false;

            await axios.delete(`${this.state.host}/delete-notice/${id}`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getNotices');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async getBusinessMonitorNotice(context) {
            await axios.get(`${this.state.host}/get-notice`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('setNotice', res.data.notices);
            })
            .catch(error => this.dispatch('snackbar/error', error))
   
        }
    }
}
