export default {
    namespaced: true,

    state: () => {
        return {
            loading: false,
            accounts: [],
            transactions: [],
            tnxCode: '',
            collection: {},
            profits: []
        }
    },

    getters: {
        loading(state) {
            return state.loading;
        },

        accounts(state) {
            return state.accounts.map((item, sl) => {
                item.sl = sl + 1;
                item.display_name = `${item.name} - ${item.id}`;
                return item;
            });
        },

        transactions(state) {
            return state.transactions.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            })
        },

        generateCode(state) {
            return state.tnxCode;
        },

        collection(state) {
            return state.collection;
        },

        profits(state) {
            return state.profits;
        }
    },

    mutations: {
        setLoading(state, loading) {
            state.loading = loading
        },

        setAccount(state, accounts) {
            state.accounts = accounts
        },

        setTnxCode(state, code) {
            state.tnxCode = code
        },

        setTransaction(state, transactions) {
            state.transactions = transactions
        },

        setCollection(state, collection) {
            state.collection = collection
        },

        setProfit(state, profit) {
            state.profits = profit
        }
    },

    actions: {
        async getExpenseAccount(context, payload) {
            context.commit('setLoading', true);
        
            await axios.post(`${this.state.host}/get-accounts`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('setAccount', res.data.accounts);
            })
            .catch(error => this.dispatch('snackbar/error', error))

            context.commit('setLoading', false);
        },

        async saveExpenseAccount(context, payload) {
            let isSuccess = false;

            let url = '';
            if(payload.id != null) {
                url = 'update-account';
            } else {
                url = 'add-account';
                delete payload.id
            }

            await axios.post(`${this.state.host}/${url}`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getExpenseAccount');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async deleteExpenseAccount(context, id) {
            let isSuccess = false;

            await axios.delete(`${this.state.host}/delete-account/${id}`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getExpenseAccount');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async getCashTransactionCode(context) {
            await axios.get(`${this.state.host}/get-cash-transaction-code`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('setTnxCode', res.data.code);
            })
            .catch(error => this.dispatch('snackbar/error', error.response.data.message))
        },

        async getCashTransactions(context, payload) {
            await axios.post(`${this.state.host}/get-cash-transactions`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('setTransaction', res.data.transactions);
            })
            .catch(error => this.dispatch('snackbar/error', error.response.data.message))
        },

        async saveCashTransaction(context, payload) {
            let response = {isSuccess: false};

            let url = '';
            if(payload.id != null) {
                url = 'update-cash-transaction';
            } else {
                url = 'add-cash-transaction';
                delete payload.id
            }

            await axios.post(`${this.state.host}/${url}`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                response.isSuccess = true;
                response.transactionId = res.data.transactionId;
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => { 
                response.isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return response;
        },

        async deleteCashTransaction(context, id) {
            let isSuccess = false;

            await axios.delete(`${this.state.host}/delete-cash-transaction/${id}`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async getCollections(context) {
            context.commit('setLoading', true);
        
            await axios.get(`${this.state.host}/get-collection-data`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('setCollection', res.data.collection);
                // context.commit('setCollection', res.data.collection.today_expense);
            })
            .catch(error => this.dispatch('snackbar/error', error))

            context.commit('setLoading', false);
        },

        async getCashBalance(context) {
            let balance = await axios.get(`${this.state.host}/get-cash-balance`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                return res.data.balance;
            })
            .catch(error => this.dispatch('snackbar/error', error))

            return balance;
        },

        async getCashStatement(context, payload) {
            let statements = await axios.post(`${this.state.host}/get-cash-statement`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                return res.data.statements;
            })
            .catch(error => this.dispatch('snackbar/error', error))

            return statements;
        },

        async getBranchwiseProfit(context) {
            await axios.get(`${this.state.host}/get-profit-balance`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('setProfit', res.data.profits)
            })
            .catch(error => this.dispatch('snackbar/error', error))

        },

        async getHeadCollectionExpense(context, payload) {
            let collection = await axios.post(`${this.state.host}/get-head-collections`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                return res.data.collections
            })
            .catch(error => this.dispatch('snackbar/error', error))

            return collection;
        }
    }
}
