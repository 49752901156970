export default {
    namespaced: true,

    state: () => {
        return {
            loading: false,
            suppliers: [],
            payments: [],
            dues: []
        }
    },

    getters: {
        loading(state) {
            return state.loading;
        },

        suppliers(state) {
            return state.suppliers.map((item, sl) => {
                item.sl = sl + 1;
                item.display_name = `${item.code} - ${item.name}`
                return item;
            });
        },

        payments(state) {
            return state.payments.map((item, sl) => {
                item.sl = sl + 1;
                return item;
            })
        },

        dues(state) {
            return state.dues;
        }
    },

    mutations: {
        setLoading(state, loading) {
            state.loading = loading
        },

        setSupplier(state, suppliers) {
            state.suppliers = suppliers
        },

        setPayment(state, payments) {
            state.payments = payments
        },

        setDue(state, due) {
            state.dues = due
        }
    },

    actions: {
        async getSuppliers(context, payload) {
            context.commit('setLoading', true);
            await axios.post(`${this.state.host}/get-suppliers`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('setSupplier', res.data.suppliers);
            })
            .catch(error => this.dispatch('snackbar/error', error))

            context.commit('setLoading', false);
        },

        async generateSupplierCode(context) {
            let newCode = await axios.get(`${this.state.host}/generate-supplier-code`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                return res.data.code;
            })
            .catch(error => this.dispatch('snackbar/error', error))
            
            return newCode;
        },

        async getSupplierDue(context, payload) {
            await axios.post(`${this.state.host}/get-supplier-due`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('setDue', res.data.dues);
            })
            .catch(error => this.dispatch('snackbar/error', error))
        },

        async saveSupplier(context, payload) {
            let isSuccess = false;

            let url = '';
            if(payload.id != null) {
                url = 'update-supplier';
            } else {
                url = 'add-supplier';
                delete payload.id
            }

            await axios.post(`${this.state.host}/${url}`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getSuppliers');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async deleteSupplier(context, id) {
            let isSuccess = false;

            await axios.delete(`${this.state.host}/delete-supplier/${id}`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
                context.dispatch('getSuppliers');
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async getSupplierPyaments(context, payload) {
            context.commit('setLoading', true);
            await axios.post(`${this.state.host}/get-supplier-payments`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                context.commit('setPayment', res.data.payments);
            })
            .catch(error => this.dispatch('snackbar/error', error))

            context.commit('setLoading', false);
        },

        async saveSupplierPayment(context, payload) {
            let isSuccess = false;

            let url = '';
            if(payload.id != null) {
                url = 'update-supplier-payment';
            } else {
                url = 'add-supplier-payment';
                delete payload.id
            }

            await axios.post(`${this.state.host}/${url}`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async deleteSupplierPayment(context, id) {
            let isSuccess = false;

            await axios.delete(`${this.state.host}/delete-supplier-payment/${id}`, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => { 
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async getSupplierLedger(context, payload) {
            let ledger = await axios.post(`${this.state.host}/get-supplier-ledger`, payload, {
                headers: {
                    'Authorization': this.getters['authorized/jwt']
                }
            })
            .then(res => {
                return res.data.ledger;
            })
            .catch(error => this.dispatch('snackbar/error', error))
            
            return ledger;
        }
    }
}
